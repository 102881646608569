<template>
  <div class="edit-avatar w-100 h-100 d-flex flex-column align-items-center">
    <base-header
      :title="$t('編輯個人頭像')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="true"
      :right-text="$t('完成')"
      @on-click-right="submitUpdate"
    ></base-header>
    <div class="edit-avatar-container scroll-bar bg-white w-100 h-100">
      <b-container class="w-100 py-4 px-3">
        <b-row class="m-0">
          <b-col
            v-for="avatar in avatars"
            :key="avatar"
            class="p-0 d-flex justify-content-start"
          >
            <div
              class="avatar-box bg-white d-flex justify-content-start position-relative"
              @click="selectedAvatar = avatar"
            >
              <img
                class="rounded-circle img-fluid"
                :srcset="
                  require(`@/assets/img/personal/avatar/${avatar}.png?srcset`)
                "
              />
              <i
                v-if="
                  selectedAvatar
                    ? selectedAvatar === avatar
                    : userData.avatar === avatar
                "
                class="icon-Check position-absolute d-flex justify-content-center align-items-center"
              ></i>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import userService from '@/services/user';
import {padStart} from 'lodash';
import {avatarsNumber} from '@/constant/env.js';

export default {
  components: {
    BaseHeader,
  },
  data() {
    return {
      selectedAvatar: '',
      avatars: [],
      avatarsNumber,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
  },
  mounted() {
    for (let i = 0; i < this.avatarsNumber; i++) {
      this.avatars.push(padStart(i, 4, '0'));
    }
  },
  methods: {
    async submitUpdate() {
      if (this.isLoading) return;
      this.$store.commit('env/setIsLoading', true);
      try {
        await userService.updateUser({
          avatar: this.selectedAvatar
            ? this.selectedAvatar
            : this.userData.avatar,
        });
        await this.$store.dispatch('user/loginWithJwt');
      } catch (error) {
        this.$Message.error(this.$t('個人資訊更改失敗'));
      } finally {
        this.$store.commit('env/setIsLoading', false);
        return this.$router.back();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-avatar {
  .edit-avatar-container {
    overflow: overlay;
    .container {
      .row {
        display: grid;
        grid-column-gap: 20px;
        grid-row-gap: 27px;
        justify-items: center;
        align-items: center;
        grid-template-columns: repeat(3, 1fr);
      }
      .row {
        .col {
          .avatar-box {
            i {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background-color: $positive;
              color: #ffffff;
              font-size: 24px;
              bottom: 0;
              right: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
